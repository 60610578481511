import React, { useState, useEffect, useCallback, useMemo } from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Link from "@amzn/meridian/link";
import Select, { SelectOption } from "@amzn/meridian/select";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import ModelSelect from "src/components/shared/modelSelect/modelSelect";
import UploadNewRevision from "src/components/validationWorkflow/uploadNewRevision";
import { HistoricalValidationFlattenCapitalized } from "src/components/validationWorkflow/dataProcess/dataProcessType";
import {
  sortTableDataByColumnName,
  transformHistoricalValidationsToTableData,
  SortDirection,
  getAccuracyFields,
} from "src/components/validationWorkflow/dataProcess/dataProcessUtil";
import {
  getCurrentGroundTruth,
  getHistoricalValidation,
  validate,
  download,
  resetCurrentGroundTruthError,
  GroundTruthOutput,
  setSelectedUploadId,
  resetHistoricalValidation,
} from "src/store/modelLifeCycleSlice";
import { getGroundTruthDataListView } from "src/store/modelLifeCycleGroundTruthSlice";
import { error } from "src/utils/toastHelper";
import { useNavigate } from "react-router-dom";
import SampleCSVTemplateLink from "src/components/shared/csvTemplate/sampleCSVTemplateLink";
import { SOURCE_ID, SOURCE_TEXT } from "src/constants";
import { formatISO8601TimestampToDateTime } from "src/utils/dateUtil";
import Text from "@amzn/meridian/text";
import Loader from "@amzn/meridian/loader";

const ValidationWorkflow = () => {
  const dispatch = useAppDispatch();
  const { selectedModelId, selectedModel } = useAppSelector(
    (state) => state.modelSlice,
  );

  const {
    selectedUploadId,
    groundTruthOutputs,
    historicalValidations,
    currentGroundTruthError,
  } = useAppSelector((state) => state.modelLifeCycleSlice);

  const { getListViewLoading } = useAppSelector(
    (state) => state.modelLifeCycleGroundTruthSlice,
  );

  const [isUploadIdCleared, setIsUploadIdCleared] = useState(false);
  const [sortColumn, setSortColumn] = useState("Run");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Descending,
  );
  const [isInitialSorted, setIsInitialSorted] = useState(false);
  const [tableData, setTableData] = useState<
    HistoricalValidationFlattenCapitalized[]
  >([]);

  const navigate = useNavigate();

  const { groundTruthDataReviewedCount, groundTruthDataTotalCount } =
    useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);

  useEffect(() => {
    if (currentGroundTruthError) {
      error(currentGroundTruthError);
      dispatch(resetCurrentGroundTruthError());
    }
  }, [currentGroundTruthError]);

  useEffect(() => {
    setIsInitialSorted(false);
    dispatch(setSelectedUploadId(""));
    dispatch(resetHistoricalValidation());
    setIsUploadIdCleared(true);
    if (selectedModelId) {
      dispatch(getGroundTruthDataListView(selectedModelId));
      dispatch(getCurrentGroundTruth({ modelId: selectedModelId }));
    }
  }, [selectedModelId]);

  useEffect(() => {
    // isUploadIdCleared: ensure dispatch(setSelectedUploadId("")) is executed
    if (isUploadIdCleared && selectedUploadId) {
      dispatch(
        getHistoricalValidation({
          modelId: selectedModelId,
          uploadId: selectedUploadId,
        }),
      );
    }
  }, [selectedUploadId, isUploadIdCleared]);

  useEffect(() => {
    if (tableData.length > 0) {
      sortTableDataByColumnName(tableData, sortColumn, sortDirection);
      setIsInitialSorted(true);
    }
  }, [tableData, sortColumn, sortDirection]);

  const onSort = useCallback(
    ({
      sortColumn,
      sortDirection,
    }: {
      sortColumn: string;
      sortDirection: SortDirection;
    }) => {
      setSortDirection(sortDirection);
      setSortColumn(sortColumn);
    },
    [],
  );

  useEffect(() => {
    if (historicalValidations) {
      setTableData(
        transformHistoricalValidationsToTableData(historicalValidations),
      );
    } else {
      setTableData([]);
    }
  }, [historicalValidations]);

  // TO-DO: get accuracyFields from selectedModel.fields
  const accuracyFields = useMemo(() => {
    if (tableData.length > 0) {
      return getAccuracyFields(
        tableData.reduce((oldest, current) =>
          current.Date < oldest.Date ? current : oldest,
        ),
      );
    }
    return {};
  }, [tableData]);

  const handleValidate = () => {
    if (selectedModelId && selectedUploadId) {
      dispatch(
        validate({
          modelId: selectedModelId,
          uploadId: selectedUploadId,
        }),
      );
      //introduce 1 second timeout to fetch the newly created record
      setTimeout(() => {
        dispatch(
          getHistoricalValidation({
            modelId: selectedModelId,
            uploadId: selectedUploadId,
          }),
        );

        setIsInitialSorted(false);
      }, 1000);
    }
  };

  const handleDownload = (jobId: string, s3URL: string) => {
    dispatch(
      download({
        jobId,
        s3URL,
        fileName: s3URL.replace("output/", ""),
      }),
    );
  };

  const toPercentage = (decimal: number, decimals = 2) =>
    (decimal * 100).toFixed(decimals) + "%";

  const groundTruthsExist = () =>
    groundTruthOutputs && groundTruthOutputs.length !== 0;

  return (
    <Column spacingInset="500" spacing="500">
      <Row
        widths={["grid-3"]}
        alignmentHorizontal={"start"}
        alignmentVertical={"bottom"}
      >
        <Column>
          <ModelSelect />
        </Column>
        {selectedModelId !== "" && getListViewLoading === "pending" && (
          <Row alignmentHorizontal={"center"}>
            <Loader size={"medium"} /> <Text>Loading ground truth...</Text>
          </Row>
        )}
        {selectedModelId !== "" && getListViewLoading === "fulfilled" && (
          <Column
            spacingInset={"0"}
            spacing={"50"}
            alignmentVertical={"bottom"}
          >
            <Row width={"100%"}>
              <Text>{`Reviewed:${groundTruthDataReviewedCount} , Total:${groundTruthDataTotalCount}`}</Text>
            </Row>
            <Row width={"justify"}>
              <Button
                onClick={() => {
                  navigate(`/validationWorkflow/${selectedModelId}`);
                }}
                disabled={groundTruthDataTotalCount === 0}
                size={"medium"}
              >
                Review ground truth
              </Button>
            </Row>
          </Column>
        )}
      </Row>
      {selectedModelId && (
        <Row>
          <UploadNewRevision />
        </Row>
      )}
      {selectedModelId !== "" && (
        <SampleCSVTemplateLink
          columnNames={[SOURCE_ID, SOURCE_TEXT].concat(
            Object.values(selectedModel.fields).map((field) => field.name),
          )}
        ></SampleCSVTemplateLink>
      )}
      {selectedModelId && (
        <Row widths={["grid-3", "grid-3"]}>
          <Select
            value={selectedUploadId}
            onChange={(id) => {
              dispatch(setSelectedUploadId(id));
              setIsInitialSorted(false);
            }}
            placeholder={
              groundTruthsExist() ? "Select a file.." : "Upload a file.."
            }
            width={300}
            disabled={!groundTruthsExist()}
          >
            {groundTruthOutputs?.map(
              ({ fileName, timestamp, uploadId }: GroundTruthOutput) => (
                <SelectOption
                  key={uploadId}
                  value={`${uploadId}`}
                  label={`${fileName}, ${formatISO8601TimestampToDateTime(timestamp)}`}
                ></SelectOption>
              ),
            )}
          </Select>
          <Button onClick={handleValidate} disabled={!selectedUploadId}>
            Validate
          </Button>
        </Row>
      )}
      {selectedModelId && isInitialSorted && tableData.length > 0 && (
        <Table
          headerRows={1}
          showDividers
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={onSort}
        >
          <TableRow>
            <TableCell sortColumn="Run">Run</TableCell>
            <TableCell sortColumn="Date">Date</TableCell>
            <TableCell sortColumn="Status">Status</TableCell>
            {Object.keys(accuracyFields).map((field) => (
              <TableCell key={field} sortColumn={field}>
                {field}
              </TableCell>
            ))}
            <TableCell>Ground Truths</TableCell>
            <TableCell>Extracted Results</TableCell>
            <TableCell>Reviewed / Total</TableCell>
          </TableRow>
          {tableData.map((row) => {
            return (
              <TableRow key={row.Id}>
                <TableCell>{row.Run}</TableCell>
                <TableCell>{row.Date}</TableCell>
                <TableCell>{row.Status}</TableCell>
                {Object.keys(accuracyFields).map((field) => (
                  <TableCell key={field} sortColumn={field}>
                    {row[field] !== undefined
                      ? toPercentage(row[field], 2)
                      : "NA"}
                  </TableCell>
                ))}
                <TableCell>
                  <Link
                    disabled={!row.S3GroundTruthFilePath}
                    onClick={() =>
                      row.S3GroundTruthFilePath &&
                      handleDownload(row.Id, row.S3GroundTruthFilePath)
                    }
                  >
                    download
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    disabled={!row.S3ExtractedResultFilePath}
                    onClick={() =>
                      row.S3ExtractedResultFilePath &&
                      handleDownload(row.Id, row.S3ExtractedResultFilePath)
                    }
                  >
                    download
                  </Link>
                </TableCell>
                <TableCell>
                  {`${row.TotalReviewedNumber} / ${row.TotalGroundTruthsNumber}`}
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </Column>
  );
};

export default ValidationWorkflow;
