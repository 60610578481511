import Auth from "@aws-amplify/auth";

export async function Init(): Promise<void> {
  const config = {
    stage: "gamma",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:bc84e035-202d-43d8-8acd-c890b5d01629",
    aws_user_pools_id: "us-east-1_NoHrIhXbG",
    aws_user_pools_web_client_id: "7j7td3l7r76k7v4kcnk0t9nb47",
    Auth: {
      identityPoolId: "us-east-1:bc84e035-202d-43d8-8acd-c890b5d01629",
      region: "us-east-1",
      userPoolId: "us-east-1_NoHrIhXbG",
      userPoolWebClientId: "7j7td3l7r76k7v4kcnk0t9nb47",
    },
    oauth: {
      domain: `whs-data-excavator-gamma-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://gamma.na.excavator.whs.amazon.dev",
      redirectSignOut: "https://gamma.na.excavator.whs.amazon.dev",
      scope: ["openid", "profile"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
