import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { addApiError, refreshPage } from "src/store/errorBoundarySlice";
import store from "../store/store";
import authService from "src/services/authService";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { v4 as uuidv4 } from "uuid";

const apiClient = axios.create({
  baseURL: "https://gamma.na.excavator-api.whs.amazon.dev",
});

axiosRetry(apiClient, {
  retries: 1, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    // use store.dispatch(getUser()) will make retry instantly, which cause more chance to having race condition
    authService.login();
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error: AxiosError) => {
    // if retry condition is not specified, by default idempotent requests are retried
    const status = error.response?.status;
    if (!status || status != 401) {
      return false;
    }
    return true;
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      store.dispatch(refreshPage());
    } else if (error.response) {
      store.dispatch(
        createToast({
          type: "error",
          message: error.response?.data.detail,
          timeout: TOAST_TIMEOUT,
        }),
      );
      store.dispatch(
        addApiError({
          key: error.response.config.url,
          value: error.response?.data.detail,
        }),
      );
    }
  },
);

export default apiClient;
