import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import {
  addFieldExample,
  getFieldDescriptionKey,
  getFieldExampleKey,
  getFieldNameKey,
  getFieldOptionsKey,
  getFieldTypeKey,
  FieldType,
  removeField,
  removeFieldExample,
  resetField,
  setFieldDescription,
  setFieldExamples,
  setFieldName,
  setFieldOptions,
  setFieldType,
  setFieldMany,
  ModelStatus,
} from "src/store/modelManagementSlice";
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import RadioButton from "@amzn/meridian/radio-button";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import plusToken from "@amzn/meridian-tokens/base/icon/plus";
import trashToken from "@amzn/meridian-tokens/base/icon/trash";
import redoToken from "@amzn/meridian-tokens/base/icon/redo";
import "./fieldEdit.scss";
import Textarea from "@amzn/meridian/textarea";
import React from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import Alert from "@amzn/meridian/alert";
import Toggle from "@amzn/meridian/toggle";

const FieldEdit = () => {
  const dispatch = useDispatch();
  const { selectedModel, selectedFieldId, errors, isCreatingField } =
    useAppSelector((state) => state.modelSlice);

  return (
    <Column
      alignmentHorizontal="center"
      spacingInset={"none 600 none 400"}
      width={"fill"}
    >
      <Row
        width={"100%"}
        widths={["fill"]}
        alignmentHorizontal={"start"}
        alignmentVertical={"bottom"}
      >
        <Input
          value={selectedModel.fields[selectedFieldId].name}
          onChange={(name) => dispatch(setFieldName(name))}
          type="text"
          placeholder="Enter field name..."
          label="Field Name"
          error={errors[getFieldNameKey(selectedFieldId)] !== undefined}
          errorMessage={errors[getFieldNameKey(selectedFieldId)]}
          disabled={selectedModel.status === ModelStatus.inactive}
        />
        {isCreatingField && (
          <Button
            minWidth={200}
            onClick={() => {
              dispatch(removeField());
              dispatch(resetField());
            }}
          >
            <Icon tokens={redoToken}></Icon>
            Return to field edit
          </Button>
        )}
      </Row>
      <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
        <Text>Field definition</Text>
      </Row>
      <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
        <Textarea
          value={selectedModel.fields[selectedFieldId].description}
          onChange={(description) => dispatch(setFieldDescription(description))}
          placeholder="Enter field definition to guide LLM extract information more accurately..."
          error={errors[getFieldDescriptionKey(selectedFieldId)] !== undefined}
          errorMessage={errors[getFieldDescriptionKey(selectedFieldId)]}
        />
      </Row>
      <Row width={"100%"} alignmentHorizontal={"start"} widths={["50%", "50%"]}>
        <Column>
          <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
            <Text>Type</Text>
          </Row>
          <Row width={"100%"} alignmentHorizontal={"start"}>
            <RadioButton
              checked={
                selectedModel.fields[selectedFieldId].type ===
                FieldType.freeText
              }
              onChange={() => {
                dispatch(setFieldType(FieldType.freeText));
                dispatch(setFieldMany(false));
              }}
              disabled={selectedModel.status === ModelStatus.inactive}
              value="Free text"
              size="medium"
            >
              Free text
            </RadioButton>
            <RadioButton
              checked={
                selectedModel.fields[selectedFieldId].type ===
                FieldType.selection
              }
              onChange={() => dispatch(setFieldType(FieldType.selection))}
              disabled={selectedModel.status === ModelStatus.inactive}
              value="Selection"
              size="medium"
            >
              Selection
            </RadioButton>
          </Row>
        </Column>
        <Column>
          <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
            <Text>Multiple</Text>
          </Row>
          <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
            <Toggle
              checked={selectedModel.fields[selectedFieldId].many}
              disabled={
                selectedModel.fields[selectedFieldId].type ===
                  FieldType.freeText ||
                selectedModel.status === ModelStatus.inactive
              }
              onChange={() => {
                dispatch(
                  setFieldMany(!selectedModel.fields[selectedFieldId].many),
                );
              }}
            ></Toggle>
          </Row>
        </Column>
      </Row>
      {errors[getFieldTypeKey(selectedFieldId)] && (
        <Row width={"100%"} alignmentHorizontal={"start"} widths={"fill"}>
          <Alert type="error" size="small">
            {errors[getFieldTypeKey(selectedFieldId)]}
          </Alert>
        </Row>
      )}
      {selectedModel.fields[selectedFieldId].type === FieldType.selection && (
        <Row width={"100%"} alignmentVertical={"bottom"}>
          <Text>Options</Text>
        </Row>
      )}
      <Row width="100%" widths={"fill"}>
        {selectedModel.fields[selectedFieldId].type === FieldType.selection && (
          <Textarea
            value={selectedModel.fields[selectedFieldId].options.join("\n")}
            placeholder={"place enter the options and separate by line..."}
            onChange={(options) => dispatch(setFieldOptions(options))}
            rows={5}
            resize={"none"}
            error={errors[getFieldOptionsKey(selectedFieldId)] !== undefined}
            errorMessage={errors[getFieldOptionsKey(selectedFieldId)]}
          />
        )}
      </Row>
      {
        <Row
          width="100%"
          alignmentHorizontal={"start"}
          alignmentVertical={"center"}
        >
          <Text>Field Example</Text>
          <Button minWidth={200} onClick={() => dispatch(addFieldExample())}>
            <Icon tokens={plusToken}></Icon>
            Add new Example
          </Button>
        </Row>
      }
      {selectedModel.fields[selectedFieldId].examples.length !== 0 && (
        <Row width="100%" widths={["48%", "48%"]}>
          <Text>Input</Text>
          <Text>Output</Text>
        </Row>
      )}
      {selectedModel.fields[selectedFieldId].examples.map((example, index) => (
        <Row width="100%" widths={["48%", "48%"]}>
          <Input
            value={example.input}
            onChange={(input) =>
              dispatch(
                setFieldExamples({
                  index: index,
                  input: input,
                }),
              )
            }
            type="text"
            placeholder="Enter field example input , add one or few sentence..."
            error={
              errors[getFieldExampleKey(selectedFieldId, index, "INPUT")] !==
              undefined
            }
            errorMessage={
              errors[getFieldExampleKey(selectedFieldId, index, "INPUT")]
            }
          />
          {selectedModel.fields[selectedFieldId].type === FieldType.freeText ? (
            <Input
              value={example.output[0]}
              onChange={(output) =>
                dispatch(
                  setFieldExamples({
                    index: index,
                    output: output,
                  }),
                )
              }
              type="text"
              placeholder="Enter field example output"
              error={
                errors[getFieldExampleKey(selectedFieldId, index, "OUTPUT")] !==
                undefined
              }
              errorMessage={
                errors[getFieldExampleKey(selectedFieldId, index, "OUTPUT")]
              }
            />
          ) : (
            <Select
              value={
                selectedModel.fields[selectedFieldId].many
                  ? example.output
                  : example.output[0]
              }
              onChange={(output) => {
                dispatch(
                  setFieldExamples({
                    index: index,
                    output: output,
                  }),
                );
              }}
              placeholder={"please select..."}
              size="medium"
              error={
                errors[getFieldExampleKey(selectedFieldId, index, "OUTPUT")] !==
                undefined
              }
              errorMessage={
                errors[getFieldExampleKey(selectedFieldId, index, "OUTPUT")]
              }
            >
              {selectedModel.fields[selectedFieldId].options.map((option) => (
                <SelectOption value={option} label={option} />
              ))}
            </Select>
          )}
          <Button
            type={"icon"}
            onClick={() => dispatch(removeFieldExample(index))}
          >
            <Icon tokens={trashToken}></Icon>
          </Button>
        </Row>
      ))}
    </Column>
  );
};

export default FieldEdit;
